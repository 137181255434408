<!--Combined GrossCreditInvoice-->
<template>
  <div class="">
    <div>
      <the-navbar-horizontal
        class="no-print"
        :navbarType="navbarType"
        :class="'text-base'"/>
    </div>

    <div class="float-right inline-flex mt-6 no-print">
      <vs-button @click="printInvoice">Print</vs-button>
    </div>

    <div class="print-invoice">


      <div class="page-content" v-if="invoice">

        <!-- Gross -->
        <div class="page-header" style="text-align: center">
          <div class="header-invoice w-full">
            <div class="header-title">
              <div class="header-title-bg flex flex-row items-center">

                <p class="header-title-text">
                  {{ invoice.invoice_data.parent_organisation.organisation_name }}
                </p>
              </div>
              <div class="header-title-bg-1">

              </div>
              <div class="header-title-bg-2">

              </div>
              <div class="header-title-bg-3">

              </div>
            </div>
            <vs-spacer></vs-spacer>
            <div class="header-invoice-details flex justify-end items-center">
              <img class=""
                   :src="getlogo()"/>
              <span class="header-invoice-tittle">
<!--              GUTSCHRIFT-->

            </span>
            </div>
          </div>
        </div>


        <table class="top-table">

          <thead>
          <tr>
            <td>
              <!--place holder for the fixed-position header-->
              <div class="page-header-space"></div>
            </td>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td>
              <div class="block mb-6 w-full">
                <div class="flex justify-end">
                  <div class="width-400 parent-org-text text-bold text-end">
                    <p>{{ invoice.invoice_data.parent_organisation.organisation_name }}</p>
                    <p>
                  <span v-if="invoice.invoice_data.parent_organisation.address">
                    {{ invoice.invoice_data.parent_organisation.address }}
                  </span>
                      <span v-if="invoice.invoice_data.parent_organisation.house_no">
                    {{ invoice.invoice_data.parent_organisation.house_no }}
                  </span>
                    </p>
                    <p>
                   <span v-if="invoice.invoice_data.parent_organisation.postal_code">
                    {{ invoice.invoice_data.parent_organisation.postal_code }}
                  </span>
                      <span v-if="invoice.invoice_data.parent_organisation.city">
                    {{ invoice.invoice_data.parent_organisation.city }}
                  </span>
                    </p>
                    <p v-if="invoice.invoice_data.parent_organisation.stnr">
                      St.Nr: {{ invoice.invoice_data.parent_organisation.stnr }}
                    </p>
                    <p v-if="invoice.invoice_data.parent_organisation.ustldnr">
                      Ustldnr: {{ invoice.invoice_data.parent_organisation.ustldnr }}
                    </p>
                  </div>
                </div>

                <div class="width-400 align-start top-side-bar">
                  <p class="text-bold">{{
                      invoice.invoice_data.child_organisation.organisation_name
                    }}</p>
                  <p>{{ invoice.organisation_first_name }} {{ invoice.organisation_last_name }}</p>
                  <p>{{ invoice.invoice_data.child_organisation.address }}
                    {{ invoice.invoice_data.child_organisation.house_no }}</p>
                  <p>{{ invoice.invoice_data.child_organisation.postal_code }}
                    {{ invoice.invoice_data.child_organisation.city }}</p>
                  <p>St.Nr: {{ invoice.invoice_data.child_organisation.stnr }}</p>
                  <p>Ustldnr: {{ invoice.invoice_data.child_organisation.ustldnr }}</p>
                </div>
                <div class="flex justify-end top-side-bar">
                  <div class="width-400 pt-2">
                    <table class="custom-customer-table w-full text-end">
                      <tr>
                        <td>
                          Kunden Nr
                        </td>
                        <td>:</td>
                        <td>{{ invoice.customer_id }}</td>
                      </tr>
                      <tr>
                        <td>
                          Beleg Nr
                        </td>
                        <td>:</td>
                        <td>{{ invoice.invoice_no }}</td>
                      </tr>
                      <tr>
                        <td class="whitespace-nowrap">
                          Lief.Datum von
                        </td>
                        <td>:</td>
                        <td>{{ invoice.invoice_data.from_date | onlyDateFormat }}</td>
                      </tr>
                      <tr>
                        <td class="whitespace-nowrap">
                          Lief.Datum bis
                        </td>
                        <td>:</td>
                        <td class="header-invoice-table-date">
                          {{ invoice.invoice_data.to_date | onlyDateFormat }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Beleg.Datum
                        </td>
                        <td>:</td>
                        <td class="header-invoice-table-date">{{
                            invoice.invoice_data.invoice_date | onlyDateFormat
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div class="text-bold text-center">
                <span class="invoice-text-title">RECHNUNG</span>
              </div>

              <div>
                <table class="custom-table-data" style="border: none">
                  <tr class="table-header">
                    <th class="width-40">Pos</th>
                    <th class="thead-item-product">Beschreibung</th>
                    <th class="width-80">Einzelpreis €</th>
                    <th class="width-40">Menge</th>
                    <th class="width-80">Betrag €</th>

                    <td class="table-data-w-10"></td>

                    <th class="width-40 data-border-left-white">Pos</th>
                    <th class="thead-item-product">Beschreibung</th>
                    <th class="width-80">Einzelpreis €</th>
                    <th class="width-40">Menge</th>
                    <th class="width-80">Betrag €</th>
                  </tr>
                  <tr
                    :class="[
                      {'table-data-border': dindex < chunkedInvoice.length - 1}
                    ]"
                    class="table-data table-bg-color" v-for="(dI, dindex) in chunkedInvoice">
                    <template v-for="(d, index) in dI">
                      <template v-if="index == 0">
                        <td class="text-center">{{ (dindex * 2) + 1 }}</td>
                        <td>{{ getTitle(d.product_name) }} - {{ d.product_type }}</td>
                        <td class="text-center no-word-break">{{
                            d.denomination | germanNumberFormat
                          }}
                        </td>
                        <td class="text-center">{{ d.quantity }}</td>
                        <td class="text-center no-word-break"
                            v-if="d.product_type == 'Flix Ticket'">
                          {{ d.betrag_gross | germanNumberFormat }}
                        </td>
                        <td class="text-center no-word-break" v-else>
                          {{ d.denomination * d.quantity | germanNumberFormat }}
                        </td>

                        <td class="table-data-w-10"></td>
                      </template>


                      <template v-else>
                        <td class="text-center data-border-left-white">{{ (dindex * 2) + 2 }}</td>
                        <td>{{ getTitle(d.product_name) }} - {{ d.product_type }}</td>
                        <td class="text-center no-word-break">{{
                            d.denomination | germanNumberFormat
                          }}
                        </td>
                        <td class="text-center">{{ d.quantity }}</td>
                        <td class="text-center no-word-break"
                            v-if="d.product_type == 'Flix Ticket'">
                          {{ d.betrag_gross | germanNumberFormat }}
                        </td>
                        <td class="text-center no-word-break" v-else>
                          {{ d.denomination * d.quantity | germanNumberFormat }}
                        </td>
                      </template>
                    </template>
                  </tr>
                  <tr class="table-data">
                    <th colspan="11" class="content">
                    </th>
                  </tr>
                  <tr class="table-horizontal-line">
                    <td colspan="11" class="content">
                    </td>
                  </tr>
                  <tr class="table-data">
                    <th style="border: none" colspan="7"></th>
                    <th colspan="2">Gesamt Netto €</th>
                    <th colspan="2">{{
                        invoice.invoice_data.gesamt_netto_gross | germanNumberFormat
                      }}
                    </th>
                  </tr>
                  <tr class="table-horizontal-line">
                    <th style="border: none" colspan="7"></th>
                    <th colspan="4" class="content">
                    </th>
                  </tr>
                  <tr class="table-data">
                    <th style="border: none" colspan="7"></th>
                    <th colspan="2">Rechnungsbetraggesamt €</th>
                    <th colspan="2">
                      {{ invoice.invoice_data.rechnungsbetrag_gesamt | germanNumberFormat }}
                    </th>
                  </tr>
                  <tr class="table-horizontal-line">
                    <td colspan="11" class="content">
                    </td>
                  </tr>
                </table>
              </div>

              <!--          <div class="align-end">-->
              <!--            <table class="table-oneline">-->
              <!--              <tr>-->
              <!--                <th>Gesamt Netto €</th>-->
              <!--                <th>{{ invoice.invoice_data.gesamt_netto_gross | germanNumberFormat }}</th>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <th>Rechnungsbetraggesamt €</th>-->
              <!--                <th>{{ invoice.invoice_data.rechnungsbetrag_gesamt | germanNumberFormat }}</th>-->
              <!--              </tr>-->
              <!--            </table>-->
              <!--          </div>-->
              <div style="text-align: start; padding-top: 8px;" class="invoice-remarks-content">
                <p><span class="text-bold">Bemerkungen :</span> {{ invoice.remarks || '-' }}</p>
              </div>
              <div style="text-align: start; padding-top: 4px;color: #626262;"
                   class="invoice-static-content">
                <p><i>Der Monat des Rechnungsdatums entspricht dem Leistungsmonat soweit sich aus
                  dem Rechnungstext
                  nicht
                  andereres ergibt. Die Ladesummen stellen einen durchlaufenden Posten dar. Wir
                  erbringen einen
                  vermittlungsumsatz für das in der Beschreibung genannte Unternehmen.</i></p>
              </div>

            </td>
          </tr>

          </tbody>

          <tfoot>
          <tr>
            <td>
              <!--place holder for the fixed-position footer-->
              <div class="page-footer-space"></div>

            </td>
          </tr>
          </tfoot>

        </table>


        <div class="pagebreak"></div>


        <!--        CreditNote-->
        <div id="page-header" class="page-header" style="text-align: center">
          <div class="header-invoice w-full">
            <div class="header-title">
              <div class="header-title-bg flex flex-row items-center">

                <p class="header-title-text">
                  {{ invoice.invoice_data.parent_organisation.organisation_name }}
                </p>
              </div>
              <div class="header-title-bg-1">

              </div>
              <div class="header-title-bg-2">

              </div>
              <div class="header-title-bg-3">

              </div>
            </div>
            <vs-spacer></vs-spacer>
            <div class="header-invoice-details flex justify-end items-center">
              <img class=""
                   :src="getlogo()"/>
              <span class="header-invoice-tittle">
<!--              GUTSCHRIFT-->

            </span>
            </div>
          </div>
        </div>

        <div class="top-table">
          <table class="top-table">

            <thead>
            <tr>
              <td>
                <!--place holder for the fixed-position header-->
                <div class="page-header-space"></div>
              </td>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>
                <div class="block mb-8 w-full">
                  <div class="flex justify-end">
                    <div class="width-400 text-bold parent-org-text align-right text-end">
                      <p>{{ invoice.invoice_data.parent_organisation.organisation_name }}</p>
                      <p>
                  <span v-if="invoice.invoice_data.parent_organisation.address">
                    {{ invoice.invoice_data.parent_organisation.address }}
                  </span>
                        <span v-if="invoice.invoice_data.parent_organisation.house_no">
                    {{ invoice.invoice_data.parent_organisation.house_no }}
                  </span>
                      </p>
                      <p>
                   <span v-if="invoice.invoice_data.parent_organisation.postal_code">
                    {{ invoice.invoice_data.parent_organisation.postal_code }}
                  </span>
                        <span v-if="invoice.invoice_data.parent_organisation.city">
                    {{ invoice.invoice_data.parent_organisation.city }}
                  </span>
                      </p>
                      <p v-if="invoice.invoice_data.parent_organisation.stnr">
                        St.Nr: {{ invoice.invoice_data.parent_organisation.stnr }}
                      </p>
                      <p v-if="invoice.invoice_data.parent_organisation.ustldnr">
                        Ustldnr: {{ invoice.invoice_data.parent_organisation.ustldnr }}
                      </p>
                    </div>
                  </div>

                  <div class="width-400 align-start top-side-bar">
                    <p class="text-bold">
                      {{ invoice.invoice_data.child_organisation.organisation_name }}</p>
                    <p>{{ invoice.organisation_first_name }} {{
                        invoice.organisation_last_name
                      }}</p>
                    <p>{{ invoice.invoice_data.child_organisation.address }}
                      {{ invoice.invoice_data.child_organisation.house_no }}</p>
                    <p>{{ invoice.invoice_data.child_organisation.postal_code }}
                      {{ invoice.invoice_data.child_organisation.city }}</p>
                    <p>St.Nr: {{ invoice.invoice_data.child_organisation.stnr }}</p>
                    <p>Ustldnr: {{ invoice.invoice_data.child_organisation.ustldnr }}</p>
                  </div>

                  <div class="flex justify-end top-side-bar">
                    <div class="width-400 align-right pt-8">
                      <table class="custom-customer-table w-full text-end">
                        <tr>
                          <td>
                            Kunden Nr
                          </td>
                          <td>:</td>
                          <td>{{ invoice.customer_id }}</td>
                        </tr>
                        <tr>
                          <td>
                            Beleg Nr
                          </td>
                          <td>:</td>
                          <td>{{ invoice.credit_note_no }}</td>
                        </tr>
                        <tr>
                          <td class="whitespace-nowrap">
                            Lief.Datum von
                          </td>
                          <td>:</td>
                          <td>{{ invoice.invoice_data.from_date | onlyDateFormat }}</td>
                        </tr>
                        <tr>
                          <td class="whitespace-nowrap">
                            Lief.Datum bis
                          </td>
                          <td>:</td>
                          <td class="header-invoice-table-date">
                            {{ invoice.invoice_data.to_date | onlyDateFormat }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Beleg.Datum
                          </td>
                          <td>:</td>
                          <td class="header-invoice-table-date">{{
                              invoice.invoice_data.invoice_date | onlyDateFormat
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="align-start text-bold mb-4 text-center">
                  <span class="invoice-text-title">GUTSCHRIFT</span>
                </div>
                <div class="align-start text-bold">
                  <table class="table-oneline">
                    <tr>
                      <td class="only-table-td">Leistungs Beleg Nr</td>
                      <td>{{ invoice.invoice_no }}</td>
                    </tr>
                  </table>
                </div>

                <div>
                  <table class="custom-table-data" style="border: none">
                    <tr class="table-header">
                      <th class="width-40">Pos</th>
                      <th class="thead-item-product">Beschreibung</th>
                      <th class="width-40">Menge</th>
                      <th class="width-80">Netto %</th>
                      <th class="width-80">Prov./Stk</th>
                      <th class="width-80">Betrag €</th>

                      <td class="table-data-w-10"></td>

                      <th class="width-40">Pos</th>
                      <th class="thead-item-product">Beschreibung</th>
                      <th class="width-40">Menge</th>
                      <th class="width-80">Netto %</th>
                      <th class="width-80">Prov./Stk</th>
                      <th class="width-80">Betrag €</th>
                    </tr>
                    <tr
                      :class="[
                      {'table-data-border': dindex < chunkedInvoice.length - 1}
                    ]"
                      class="table-data table-bg-color" v-for="(dI, dindex) in chunkedInvoice">
                      <template v-for="(d, index) in dI">
                        <template v-if="index == 0">
                          <td class="text-center">{{ (dindex * 2) + 1 }}</td>
                          <td>{{ getTitle(d.product_name) }} -
                            {{ d.denomination | germanNumberFormat }}
                            - {{ d.product_type }}
                          </td>
                          <td class="text-center">{{ d.quantity }}</td>
                          <td class="text-center no-word-break">{{ d.netto_discount }}</td>
                          <td class="text-center no-word-break">{{ d.prov_stk }}</td>
                          <td class="text-center no-word-break">{{
                              (d.quantity * d.prov_stk) | germanNumberFormat
                            }}
                          </td>

                          <td class="table-data-w-10"></td>
                        </template>

                        <template v-else>
                          <td class="text-center data-border-left-white">{{ (dindex * 2) + 2 }}</td>
                          <td>{{ getTitle(d.product_name) }} -
                            {{ d.denomination | germanNumberFormat }}
                            - {{ d.product_type }}
                          </td>
                          <td class="text-center">{{ d.quantity }}</td>
                          <td class="text-center no-word-break">{{ d.netto_discount }}</td>
                          <td class="text-center no-word-break">{{ d.prov_stk }}</td>
                          <td class="text-center no-word-break">{{
                              (d.quantity * d.prov_stk) | germanNumberFormat
                            }}
                          </td>
                        </template>
                      </template>
                    </tr>
                    <tr class="table-data">
                      <th colspan="13" class="content">
                      </th>
                    </tr>
                    <tr class="table-horizontal-line">
                      <td colspan="13" class="content">
                      </td>
                    </tr>
                    <tr class="table-data">
                      <th style="border: none" colspan="9"></th>
                      <th colspan="2">Gesamt Netto €</th>
                      <th colspan="2">{{
                          invoice.invoice_data.gesamt_netto_credit_note | germanNumberFormat
                        }}
                      </th>
                    </tr>
                    <tr class="table-data">
                      <th style="border: none" colspan="9">
                      </th>
                      <th colspan="2">{{ invoice.tax }}% Ust €</th>
                      <th colspan="2">{{
                          invoice.invoice_data.percentage_19_ust | germanNumberFormat
                        }}
                      </th>
                    </tr>

                    <tr class="table-horizontal-line">
                      <th style="border: none" colspan="9"></th>
                      <th colspan="4" class="content">
                      </th>
                    </tr>
                    <tr class="table-data">
                      <th style="border: none" colspan="9">


                      </th>
                      <th colspan="2">Gutschriftsbetrag gesamt €</th>
                      <th colspan="2">{{
                          invoice.invoice_data.gutschriftsbetrag_gesamt | germanNumberFormat
                        }}
                      </th>
                    </tr>

                    <tr class="table-horizontal-line">
                      <td colspan="13" class="content">
                      </td>
                    </tr>
                  </table>
                </div>
                <div style="text-align: start; padding-top: 24px;color: black; font-size: 14px !important; font-weight: bold;"
                     v-if="invoice.invoice_data.customer_open_amount">
                  <p v-if="invoice.invoice_data.customer_open_amount && invoice.invoice_data.customer_open_amount > 0">
                    Saldo bis {{ invoice.invoice_data.to_date | onlyDateFormat }} {{ Math.abs(invoice.invoice_data.customer_open_amount) | germanNumberFormat }} Soll
                  </p>
                  <p v-if="invoice.invoice_data.customer_open_amount && invoice.invoice_data.customer_open_amount < 0">
                    Saldo bis {{ invoice.invoice_data.to_date | onlyDateFormat }} {{ Math.abs(invoice.invoice_data.customer_open_amount) | germanNumberFormat }} Haben
                  </p>
                </div>
                <div style="background: #1e90ff;height: 2px;margin-top: 12px;">
                </div>
                <div style="text-align: center; padding-top: 24px;color: #626262"
                     class="invoice-static-content">
                  <p><i>Der Monat des Belegsdatums entspricht dem Leistungsmonat soweit sich aus dem
                    Rechnungstext nicht
                    andereres ergibt</i></p>
                </div>

              </td>
            </tr>

            </tbody>

            <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>

              </td>
            </tr>
            </tfoot>

          </table>

          <div class="pagebreak"
               v-if="activeUserInfo.account_role == 2 || (activeUserInfo.account_role == 1 && invoice.invoice_data.parent_organisation.customer_id == activeUserInfo.customer_id)"></div>

          <table class="top-table"
                 v-if="activeUserInfo.account_role == 2 || (activeUserInfo.account_role == 1 && invoice.invoice_data.parent_organisation.customer_id == activeUserInfo.customer_id)">

            <thead>
            <tr>
              <td>
                <!--place holder for the fixed-position header-->
                <div class="page-header-space"></div>
              </td>
            </tr>
            </thead>

            <tbody>
            <!--          v-if="invoice.wallet_history_data.length > 0"-->
            <tr>
              <td>
                <div style="">

                  <div class="text-bold text-center">
                    <span class="invoice-text-title">Zahlungsliste</span>
                  </div>
                  <table class="custom-table-data" style="border: none">
                    <tr class="table-header">
                      <th>Pos</th>
                      <th class="thead-item-product">Datum</th>
                      <th>Betrag/€</th>
                      <th>Zahlungsart</th>
                    </tr>

                    <tr
                      :class="[
                      {'table-data-border': index < invoice.wallet_history_data.length - 1}
                    ]"
                      class="table-data" v-for="(d, index) in invoice.wallet_history_data">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td class="text-center">{{ d.created | onlyDateFormat }}</td>
                      <td class="text-center">{{ d.value | germanNumberFormat }}</td>
                      <td class="text-center">{{ d.transaction_mode | title | uppercase }}</td>
                    </tr>
                    <tr class="table-data">
                      <th colspan="4" class="content">
                      </th>
                    </tr>
                    <tr class="table-horizontal-line">
                      <td colspan="4" class="content">
                      </td>
                    </tr>
                    <tr class="table-data">
                      <th colspan="1"></th>
                      <th class="text-center">Zwichensumme</th>
                      <th>{{ invoice.wallet_history_total_value | germanNumberFormat }}</th>
                      <th style="border: none"></th>
                    </tr>

                    <tr class="table-horizontal-line">
                      <td colspan="4" class="content">
                      </td>
                    </tr>
                  </table>
                </div>

              </td>
            </tr>

            </tbody>

            <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>

              </td>
            </tr>
            </tfoot>

          </table>


          <div class="pagebreak" v-if="activeUserInfo.account_role === 1"></div>


          <table class="top-table" v-if="activeUserInfo.account_role === 1">

            <thead>
            <tr>
              <td>
                <!--place holder for the fixed-position header-->
                <div class="page-header-space"></div>
              </td>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>
                <div style="">

                  <div class="text-center text-bold ">
                    <span class="invoice-text-title">Tagesbericht</span>
                  </div>
                  <table class="custom-table-data" style="border: none">
                    <tr class="table-header">
                      <th>Pos</th>
                      <th class="thead-item-product">Datum</th>
                      <th>Einkauf/€</th>
                      <th>Verkauf/€</th>
                      <th>Provision/€</th>
                    </tr>

                    <tr
                      :class="[
                      {'table-data-border': index < invoice.daily_report_data.table_data.length - 1}
                    ]"
                      class="table-data" v-for="(d, index) in invoice.daily_report_data.table_data">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td class="text-center">{{ d.date | onlyDateFormat }}</td>
                      <td class="text-center">{{ d.buying_amount | germanNumberFormat }}</td>
                      <td class="text-center">{{ d.selling_amount | germanNumberFormat }}</td>
                      <td class="text-center">{{ d.discount | germanNumberFormat }}</td>
                    </tr>
                    <tr class="table-data">
                      <th colspan="5" class="content">
                      </th>
                    </tr>
                    <tr class="table-horizontal-line">
                      <td colspan="5" class="content">
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2" style="border: none"></th>
                      <th>{{
                          invoice.daily_report_data.total_buying_amount | germanNumberFormat
                        }}
                      </th>
                      <th>{{
                          invoice.daily_report_data.total_selling_amount | germanNumberFormat
                        }}
                      </th>
                      <th>{{ invoice.daily_report_data.total_discount | germanNumberFormat }}</th>
                    </tr>
                    <tr class="table-horizontal-line">
                      <td colspan="5" class="content">
                      </td>
                    </tr>
                  </table>
                </div>


              </td>
            </tr>

            </tbody>

            <tfoot>
            <tr>
              <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>

              </td>
            </tr>
            </tfoot>

          </table>
        </div>

        <div id="page-footer" class="page-footer text-xl" style="margin-top: 20px;">
          <template v-if="false">
            <img :src="invoice.invoice_data.parent_organisation.invoice_footer"/>
          </template>
          <template v-else>
            <div class="flex flex-col text-center">
              <p class="">
                <strong>Geschäftsführer/in:</strong>
                {{ invoice.invoice_data.parent_organisation.director_name }}
                <span v-if="invoice.invoice_data.parent_organisation.email ">
                  <strong>Email:</strong> {{ invoice.invoice_data.parent_organisation.email }}
                </span>
                <span v-if="invoice.invoice_data.parent_organisation.website">
                  <strong>Website:</strong> {{ invoice.invoice_data.parent_organisation.website }}
                </span>
              </p>
              <p>
                <strong>HRB Nr:</strong>{{ invoice.invoice_data.parent_organisation.hrb_nr }}
                <span class=""
                      v-if="invoice.invoice_data.parent_organisation.iban || invoice.invoice_data.parent_organisation.bic">
                Bankverbindung:
                <span v-if="invoice.invoice_data.parent_organisation.iban">
                   <strong>IBAN: </strong> {{ invoice.invoice_data.parent_organisation.iban }}
                  </span>
                <span v-if="invoice.invoice_data.parent_organisation.bic">
                  <strong>BIC:</strong> {{ invoice.invoice_data.parent_organisation.bic }}
                </span>
              </span>
              </p>
            </div>

          </template>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import themeConfig from "../../../../../themeConfig";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal";
import * as adminNav from "@/layouts/components/vertical-nav-menu/navMenuItems";
import * as resellerNav from "@/layouts/components/vertical-nav-menu/resellerNavMenuItems";
import * as sellerNav from "@/layouts/components/vertical-nav-menu/sellerNavMenuItems";

export default {
  name: "ViewInvoiceCredit",
  data() {
    return {
      navbarType: themeConfig.navbarType || 'floating',
    }
  },
  props: {
    invoiceId: {
      type: String,
    }
  },
  components: {
    TheNavbarHorizontal,
  },
  computed: {

    chunkedInvoice() {
      const result = []
      let arr = this.invoice.invoice_data.table_data;
      for (let i = 0; i < arr.length; i += 2)
        result.push(arr.slice(i, i + 2))
      return result
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    getNavMenuItems() {
      const role = this.activeUserInfo.account_role
      switch (role) {
        case 0:
          adminNav.reload()
          return adminNav.navMenuItems
        case 1:
          resellerNav.reload()
          return resellerNav.navMenuItems
        case 2:
          sellerNav.reload()
          return sellerNav.navMenuItems
        case 3:
          adminNav.reload()
          return adminNav.navMenuItems
        default:
          return []
      }
    },
    invoice() {
      return this.$store.state.invoice.invoice
    }
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerText = ""
    setTimeout(() => {
      try {
        document.getElementsByClassName('theme-dark')[0].classList = 'no-theme-dark';
      } catch (e) {
      }
    }, 3000)
    this.fetchInvoice();
  },
  beforeDestroy() {
    setTimeout(() => {
      try {
        document.getElementsByClassName('no-theme-dark')[0].classList = 'theme-dark';
      } catch (e) {
      }
    }, 3000)
  },
  created() {
    setTimeout(() => {
      try {
        document.getElementsByTagName('footer')[0].remove();
      } catch (e) {
      }
    }, 3000)
  },
  methods: {

    getlogo() {
      if (this.invoice.invoice_data.parent_organisation.logo) {
        return 'https://pjtelesoftgmbh.de' + this.invoice.invoice_data.parent_organisation.logo
      } else {

        return 'https://pjtelesoftgmbh.de' + this.invoice.parent_organisation_logo
      }
    },
    getTitle(d) {
      if (typeof (d) == "string") {
        return d;
      }
      try {
        let keys = Object.keys(d);
        if (keys.indexOf('provider_name_with_country') !== -1) {
          return d.provider_name_with_country
        } else if (keys.indexOf('provider') !== -1) {
          return d.provider
        } else {
          return '-';
        }
      } catch (e) {
        return '-';
      }
    },
    printInvoice() {
      document.title = `${this.invoice.credit_note_no}`
      window.print();
    },
    fetchInvoice() {
      let payload = {invoice_id: this.invoiceId}
      this.$vs.loading()
      return this.$store.dispatch('invoice/fetchInvoice', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  }
}
</script>


<style lang="scss">

.layout--full-page {
  background: white !important;
}

.print-invoice {
  font-size: 20px !important;
  color: black !important;
  font-family: serif !important;
  padding: 10px 16px;


  .text-end {
    text-align: end;
  }

  .header-title {
    font-weight: bold;
    font-size: 4rem;
  }

  th, td {
    font-size: 14px !important;
  }

  .text-bold {
    font-weight: bold;
  }

  .top-table {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .page-footer {
    //position: fixed;
    //bottom: 0;
    width: 100%;
    border-top: 1px solid #0000003D;
    color: black;
    background-color: white;
  }

  .page-header {
    //position: fixed;
    top: 0mm;
    width: 100%;
  }

  .page {
    page-break-after: always;
  }

  .page-head-image {
    height: 100px;
  }

  .align-end {
    text-align: start;
    display: flex;
    justify-content: flex-end;
  }

  .parent-org-text {
    font-size: 16px !important;
  }

  .width-400 {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
  }

  .align-start {
    text-align: start;
  }

  .custom-customer-table {
    tr {
      td {
        padding-right: 10px;
      }
    }
  }

  .table-oneline {
    border: 1px solid #000000;

    tr {
      td, th {
        border: 1px solid #000000;
        padding: 0px 10px;
      }
    }
  }

  .header-invoice {
    display: flex;
    height: 86px;
    background: white;

    .header-title {
      display: inline-flex;
      flex: 1 1 100%;
      margin-right: 48px;
    }

    .header-title-text {
      color: white;
      font-size: 52px;
      text-align: start;
      padding: 0 16px;
      align-content: center;
      height: 86px;
      display: grid;

    }

    .header-title-bg {
      background: #1e90ff;
      height: 86px;
      width: 100%;
      -webkit-clip-path: polygon(0% 15%, 92.68% 15%, 100% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 92% 0%, 100% 100%, 0% 100%);
    }

    .header-title-bg-1, .header-title-bg-2, .header-title-bg-3 {
      height: 86px;
      width: 22px;
      background: #1e90ff;
      transform: skew(47deg, 0deg)
    }

    .header-title-bg-2, .header-title-bg-3 {
      margin-left: 26px;
    }

    .header-invoice-details {
      //width: 273px;
      height: 86px;
      text-align: start;
      display: flex;
      justify-content: flex-end;

      img {
        object-fit: contain;
        max-height: 70px;
      }

      .header-invoice-tittle {
        font-size: 44px;
        color: #1e90ff;
        font-weight: bolder;
        align-content: center;
        height: 113px;
        display: grid;
      }

      .header-invoice-table {
        border: none;
        width: 100%;

        .header-invoice-table-date {
          text-align: end;
        }
      }
    }

  }

  .invoice-text-title {
    background: #1e90ff;
    padding: 8px 10px;
    color: white;
    font-size: 26px !important;
  }


  .only-table-td {
    color: white;
    background: #0f163a;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .custom-table-data {
    width: 100%;
    margin-top: 12px;
    border: none;


    tr {
      th {
        text-align: center;
        border: none;
        padding: 0px 6px;
      }

      td {
        border: none;
        padding: 0px 4px;
      }
    }


    .table-header {
      color: white !important;
      background: #0f163a;

      .thead-item-product {
        background: #1e90ff;
        clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%);
      }

      th {
        background: #0f163a;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .data-border-left-white {
        //border-left: 10px solid #FFFFFF;
      }
    }

    .data-border-left-black {
      //border-left: 2px solid #000000;
    }

    .table-data-border {
      td {
        border-bottom: .5px solid #b9b9b9a6;
      }
    }

    .table-bg-color {
      background: #f2f2f2;
    }

    .table-bg-color:nth-child(even) {
      background: #FFFFFF !important;
    }

    .table-data {
      td {
        padding-top: 4px;
        padding-bottom: 4px;
      }


      th {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    .table-horizontal-line .content {
      height: 2px;
      margin-top: 10px;
      margin-bottom: 10px;
      background: #1e90ff;
    }

    .table-data-w-10 {
      width: 10px;
      height: 6px;
      border: none !important;
      background: #FFFFFF !important;
    }

  }

  td {
    word-break: break-word;
  }

  .top-side-bar {
    font-size: 16px !important;
  }

  @page {
    size: A4;
    margin: 10mm 0;
  }
  @media print {
    background-color: #fff !important;
    .pagebreak {
      page-break-after: always;
      break-after: page;
    }

    font-size: 10px !important;

    thead {
      display: table-header-group;
    }
    th, td {
      font-size: 10px !important;
      padding: 0 1px !important;
    }
    tfoot {
      display: table-footer-group;
    }
    .width-400 {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }


    button {
      display: none;
    }
    body {
      margin: 10mm 0;
      background: white;
    }
    #page-footer {
      position: fixed;
      bottom: 0;
    }
    .page-header {
      position: fixed;
      top: 0;
      padding-right: 32px;
    }

    .page-header-space {
      height: 60px;
      background-color: white;
    }
    .page-footer-space {
      height: 32px;
      background-color: white;
    }

    .page-footer {
      width: 100%;
      border-top: 1px dotted #0000003D;
      border-bottom: 1px dotted #0000003D;
      color: black;
      background-color: white;
      font-size: 10px !important;
      line-height: 1rem;
    }

    .header-invoice-tittle {
      font-size: 30px !important;
    }

    .header-title-text {
      font-size: 28px !important;
    }

    .invoice-text-title {
      font-size: 24px !important;
    }

    .custom-customer-table {
      tr {
        td {
          padding-right: 0;
        }
      }
    }

    .header-title-bg {
      -webkit-clip-path: polygon(0% 0%, 88% 0%, 100% 100%, 0% 100%) !important;
      clip-path: polygon(0% 0%, 88% 0%, 100% 100%, 0% 100%) !important;
    }

    .header-title-text, .header-invoice, .header-invoice-details, .header-title-bg, .header-title-bg-1, .header-title-bg-2, .header-title-bg-3 {
      height: 56px !important;
    }
    .header-invoice-details {
      display: flex;
      justify-content: flex-end;

      img {
        object-fit: contain;
        max-height: 52px;
      }

      .header-invoice-tittle {
        height: 56px !important;
      }
    }


    .print-invoice {
      .header-invoice {
        background-color: white;
      }
    }

    .top-side-bar {
      th, td {
        font-size: 14px !important;
      }

      p {
        font-size: 14px !important;
      }
    }

    .parent-org-text {
      th, td {
        font-size: 15px !important;
      }

      p {
        font-size: 15px !important;
      }

      font-size: 15px !important;
    }
    .width-80 {
      word-break: keep-all;
      max-width: 90px !important;
      min-width: 45px !important;
    }
    .width-40 {
      word-break: keep-all;
      max-width: 60px !important;
      min-width: 30px !important;
    }
    .no-word-break {
      word-break: keep-all;
    }
  }

  .invoice-static-content {
    text-align: center;
    padding-top: 34px;
    color: #626262 !important;
  }

  footer {
    display: none !important;
  }

  #page-footer {

    text-align: center;
  }

  .page-header {
    text-align: center;
  }

}
</style>
